.mainProductView {
  display: -webkit-inline-flex;
  flex-direction: column;
  width: 28vmin;
  height: 40vmin;
  margin-left: 2vmin;
  margin-top: 1vmin;
  position: relative;
}


.productTextLabelUnder {
  display: inline-flex;
  font-family: 'Open Sans', sans-serif;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  margin-top: 1vmin;
  justify-content: center;
  font-size: 2.7vmin;
  width: 26vmin;
  text-align: center;
  flex-wrap: pre-wrap;
  white-space: pre-wrap;
  height: 10vmin;
  white-space: pre-line;
  overflow: visible;
  text-overflow: ellipsis;
}

.cartProduct {
  /* margin-top:3px;
    margin-left:1vmin; */

  background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 26vmin;
  height: 26vmin;
  /* background-color:rgba(0,0,0,0.1); */
  justify-content: space-between;
  -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;


}

.cartProductGreen {
  /* margin-top:3px;
    margin-left:1vmin; */
  /* background-size: cover; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 26vmin;
  height: 26vmin;
  justify-content: space-between;
  -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;




}

.cartProductGreenMask {
  /* margin-top:3px;
    margin-left:1vmin; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 26vmin;
  height: 26vmin;
  justify-content: space-between;
  /* -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  -webkit-border-radius: 10px; */
  border-radius: 10px;
  box-shadow: none;



}

.boxShaddowClass {
  -webkit-transition: all 300ms cubic-bezier(0.565, 0.220, 0.530, 1);
  /* older webkit */
  -webkit-transition: all 300ms cubic-bezier(0.565, 0.220, 0.530, 1.375);
  -moz-transition: all 300ms cubic-bezier(0.565, 0.220, 0.530, 1.375);
  -o-transition: all 300ms cubic-bezier(0.565, 0.220, 0.530, 1.375);
  transition: all 300ms cubic-bezier(0.565, 0.220, 0.530, 1.375);
  /* custom */

  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 26vmin;
  height: 26vmin;
  justify-content: space-between;
  -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 26vmin;
  height: 26vmin;
  box-shadow: inset 0 0 0 100vw rgba(255, 255, 255, 0.1);
}

.topItems {
  position: absolute;
  height: 8vmin;
  width: 100%;
  display: flex;
  flex-direction: row;
  /*  background-color:green; */

}

.leftTop {
  margin-left: 2vmin;
  display: flex;
  color: red;
  font-size: 4vmin;
  z-index: 400;
  align-items: center;
  /*  background-color:green;  */

}

.rightTop {
  display: flex;
  width: 70%;

  font-size: 4vmin;
  color: white;

  ;
  justify-content: flex-end;
  padding-right: 1vmin;
  /*  justify-content: left; align horizontal */
  align-items: center;
  /* align vertical */
  /*  background-color:red; */
}

.leftBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  color: white;
  width: 30%;
  height: 30%;
  /* background-color:orange; */

}
.leftBottomWithAdmin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  color: white;
  width: 100%;
  height: 30%;
}
.rightBottom {
  /* display: flex;
  justify-content: flex-end;
  align-items: flex-end; */
  font-size: 1.2em;
  color: white;
 
  position: static;
  display: inline-block;
  bottom:50;
  right:-100;

  /* background-color:orange; */

}