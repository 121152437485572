.rotation-wrapper-outer {
    display: table;
    /* width:100vw; */

  }
  .rotation-wrapper-inner {
    padding: 50% 0;
    height: 0;
  }
  .element-to-rotate {
    rotate: 90;
      /* image-orientation:90deg; */
      width:100vw;
    /* display: block;
    transform-origin: top left;
    /* Note: for a CLOCKWISE rotation, use the commented-out
       transform instead of this one. */
    /* transform: rotate(-90deg) translate(-100%); */
     /* transform: rotate(90deg) ;
    margin-top: -50%; */
  
    /* Not vital, but possibly a good idea if the element you're rotating contains
       text and you want a single long vertical line of text and the pre-rotation
       width of your element is small enough that the text wraps: */
    /* white-space: nowrap; */ 
  }