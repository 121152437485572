.CategoryDescription {
    color:white;
    font-size: 5vh;
    height:10vh;
    margin-top:3vh;
}

.ProductList {
    display: flex;
    flex-wrap: wrap;
}
    


 

.wrapperCategory {
  
    max-width:100vw;
   overflow: hidden;
   display:flex;
   flex-direction: column;

}
.ProductListScroll {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 90vmin;
    white-space: nowrap
}
  
.categoryCSS {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 90vmin;
    white-space: nowrap;
    width:95vw;
}
  
.categoryCSS4 {
    
   
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: nowrap
}
.categoryCSS5 {
    
   
    display: flex;
    flex-direction: row;
   align-items: center;
   justify-content: center;
   align-content: center;;
  
}
